import { store } from '../../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';

const RANGECOND = 'AttackStage';

export interface AttackStageItem {
  StageOver3Months: number;
  StageOver6Months: number;
  StageOver12Months: number;
}

@Module({ dynamic: true, store, name: 'attackStage' })
class AttackStage extends VuexModule {
  private dataYearMap: Record<string, AttackStageItem> = {};

  @Action({ commit: 'setAttackStageData' })
  public fetchAttackStageData() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND)
      .then((data) => {
        if (data.length > 0) {
          return data;
        } else {
          throw new Error(RANGECOND + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  @Mutation
  public setAttackStageData(data: any[]) {
    try {
      const mapItem = data.reduce<Record<string, AttackStageItem>>((pre, cur) => {
        const key = cur['DataType'].split('#')[1];
        const item: AttackStageItem = {
          StageOver3Months: Number(cur['StageOver3Months']),
          StageOver6Months: Number(cur['StageOver6Months']),
          StageOver12Months: Number(cur['StageOver12Months']),
        };
        return {
          [key]: item,
          ...pre,
        };
      }, {});
      Object.assign(this.dataYearMap, mapItem);
    } catch (error) {
      console.error(error, 'failed to parse AttackStage data');
      Object.assign(this.dataYearMap, {});
    }
  }

  get stageItem() {
    return this.dataYearMap;
  }
}

export const AttackStageModule = getModule(AttackStage);
