import { store } from '../../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';

const RANGECOND = 'AvgActivity';

@Module({ dynamic: true, store, name: 'avgActivity' })
class AvgActivity extends VuexModule {
  private dataYearMap: Record<string, number> = {};

  @Action({ commit: 'setAvgActivityData' })
  public fetchAvgActivityData() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND)
      .then((data) => {
        if (data.length > 0) {
          return data;
        } else {
          throw new Error(RANGECOND + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  @Mutation
  public setAvgActivityData(data: any[]) {
    try {
      const mapItem = data.reduce<Record<string, number>>((pre, cur) => {
        const key = cur['DataType'].split('#')[1];
        const value = Number(cur['AvgActivity']);
        return {
          [key]: value,
          ...pre,
        };
      }, {});
      Object.assign(this.dataYearMap, mapItem);
    } catch (error) {
      console.error(error, 'failed to parse AvgActivityData data');
      Object.assign(this.dataYearMap, {});
    }
  }

  get avgActivity() {
    return this.dataYearMap;
  }
}

export const AvgActivityModule = getModule(AvgActivity);
