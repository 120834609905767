import { store } from '../../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';
import { RiskGroup } from './peer-benchmarking';
import { Nullable } from '@/types';
import { ChTimeItem, getCbhTimeItem } from '@/utilities';

const RANGECOND_BREACH_RATIO_CURRENT = 'BreachRatio#current';

type BreachRatioQueryResponse = {
  RiskGroupStart: RiskGroup;
  RiskGroupEnd: RiskGroup;
  ChTimestamp: string;
  DateRangeEnd: string;
  DateRangeStart: string;
  Ratio: string;
};

export type BreachRatioItem = {
  RiskGroupStart: RiskGroup;
  RiskGroupEnd: RiskGroup;
  ChTimestamp: ChTimeItem;
  DateRangeEnd: ChTimeItem;
  DateRangeStart: ChTimeItem;
  Ratio: number;
};

@Module({ dynamic: true, store, name: 'breachRatio' })
class BreachRatio extends VuexModule {
  currentData: Nullable<BreachRatioItem> = null;

  @Action({ commit: 'setBreachBreachRatio' })
  public async fetchCompanyBreachRatio() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_BREACH_RATIO_CURRENT)
      .then((data) => {
        if (data.length > 0) {
          return data;
        } else {
          throw new Error(RANGECOND_BREACH_RATIO_CURRENT + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  @Mutation
  public setBreachBreachRatio(data: BreachRatioQueryResponse[]) {
    try {
      if (!Array.isArray(data) && data[0])
        throw new Error('setBreachBreachRatio: data is not array');
      const item: BreachRatioItem = {
        RiskGroupStart: data[0].RiskGroupStart,
        RiskGroupEnd: data[0].RiskGroupEnd,
        ChTimestamp: getCbhTimeItem(data[0].ChTimestamp),
        DateRangeEnd: getCbhTimeItem(data[0].DateRangeEnd),
        DateRangeStart: getCbhTimeItem(data[0].DateRangeStart),
        Ratio: Number(data[0].Ratio),
      };

      this.currentData = item;
    } catch (error) {
      console.error('failed to parse BreachRatioItem data: ');
      this.currentData = null;
    }
  }

  get current() {
    return this.currentData;
  }
}

export const BreachRatioModule = getModule(BreachRatio);
